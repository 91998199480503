@import '../../../../themes/base_colors';
@import '../../../../themes/color_palette';

@mixin meeting-presentation-view-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .presentation-viewer-container {
    background: lighten(black, 10%);
    
    .agenda-container {
      background: $wd-light-background;
    }
  }
}

@mixin meeting-presentation-view-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .presentation-viewer-container {
    background: $wd-dark-background;
  
    .agenda-container {
      background: $wd-dark-background;
    }
  }

}
