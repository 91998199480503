@import "~@toast-ui/editor/dist/toastui-editor-viewer.css";
@import '~@toast-ui/editor/dist/toastui-editor.css';
@import '~@toast-ui/editor/dist/theme/toastui-editor-dark.css';

@charset "utf-8";

.toast-editor-container {
  max-width: 100%;
}

.toastui-editor-defaultUI, .toastui-editor-defaultUI .ProseMirror {
  border: none;
  padding: 15px 0;
}

.toastui-editor-md-container, .toastui-editor-ww-container {
  background: transparent;
}

.toastui-editor-dark .toastui-editor-md-container, .toastui-editor-dark .toastui-editor-ww-container {
  background: transparent;
}

// TOAST EDITOR TOOLBAR
.toastui-editor-dark .toastui-editor-defaultUI-toolbar {
  background: transparent;
  padding: 0;
  border-bottom: 1px solid rgba(white,0.12);

  button {
    border: 1px solid transparent;
  }
}

.toastui-editor-defaultUI-toolbar {
  background: transparent;
  padding: 0;

  button {
    border: 1px solid transparent;
  }
}

// Safari css hack
// Todo check if still needed
// @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio: 0) {
//   .toastui-editor-defaultUI-toolbar {
//     .toastui-editor-toolbar-group {
//       display: block;
//     }
//   }
// }

// TOAST EDITOR CONTENTS
.toastui-editor-contents {
  color: $wd-text;
  font-size: 16px;
  margin: 0;
  padding: 5px;
  overflow-x: auto !important;
  z-index: 0;

  p {
    margin: 10px 0;
    color: $wd-text;
  }

  del {
    color: #999;
  }

  blockquote {
    margin: 14px 0;
    border-left: 4px solid #e5e5e5;
    padding: 0 16px;
    min-height: 20px;
    color: #999;

    p, ul, ol {
      color: #999;
    }
  }

  pre {
    margin: 2px 0 8px;
    padding: 18px;
    background-color: #f9f2f4;

    &.addon {
      border: 1px solid #e8ebed;
      background-color: #fff;
    }
  }

  ul, menu, ol, dir {
    color: $wd-text;
  }

  ul > li::before {
    content: '';
    background-color: $wd-primary;
    margin-top: 10px;
  }

  ol > li::before {
    content: '.' counter(li);
    color: $wd-primary;
  }

  hr {
    border: none;
    height: 1px;
    background: rgba(0,0,0,0.12);
  }

  a {
    color: $wd-primary;

    &:hover {
      color: darken($wd-primary, 5%);
    }
  }
}

.wedecide-dark-theme {
  .toastui-editor-contents {
    p, ul, menu, ol, dir {
      color: white;
    }

    ul > li::before {
      background-color: $wd-primary;
    }

    ol > li::before {
      color: $wd-primary;
    }
  }
}

.more.toastui-editor-toolbar-icons {
  width: 30px;
  background-position-x: -413px;
}

@media screen and (max-width: 500px) {
  .toastui-editor-contents {
    p {
      margin: 0;
    }
  }
}

