@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;

@include mat.core();
@include cdk.text-field();

@import './themes/library-styles';
@import './themes/base_colors';
@import './themes/color_palette';
@import './themes/wedecide-light-theme';
@import './themes/wedecide-dark-theme';
@import './themes/custom';
@import './themes/material-custom/buttons';
@import './themes/material-custom/progress-bar';
@import './themes/material-custom/card';
@import './themes/material-custom/expansion-panel';
@import './themes/material-custom/typography';

@import './app/shared/layout/navbar/navbar.component.scss-theme';
@import './app/shared/layout/sidenav/sidenav.component.scss-theme';
// @import './app/manage/list/list.component.scss-theme';
// @import './app/decision-card/card-detail-text-edit.component.scss-theme';
// @import './app/decision-card/card-detail.component.scss-theme';
// @import './app/decision-card/fact-box/fact-box.component.scss-theme';
@import './app/meeting-manager/meeting-detail/meeting-detail.component.scss-theme';
@import './app/meeting-manager/meeting-manager/meeting-manager.component.scss-theme';
@import './app/meeting-manager/decider-view/decider-view.component.scss-theme';
@import './app/meeting-manager/meeting-list/meeting-list.component.scss-theme';
@import './app/meeting-manager/overlay/presentation-viewer/presentation-viewer.component.scss-theme';
@import './app/meeting-manager/meeting-detail/minutes/minutes.component.scss-theme';
@import './app/circular-resolutions/circular-resolutions/circular-resolutions.component.scss-theme';
@import './app/shared/modules/group-filter/group-filter.component.scss-theme';
@import './app/shared/modules/algolia-meeting-decision-list-item/algolia-meeting-decision-list-item.component.scss-theme';
@import './app/shared/modules/algolia-circular-resolution-list-item/algolia-circular-resolution-list-item.component.scss-theme';
@import './app/search/search.component.scss-theme';
@import './app/auth/auth.component.scss-theme';
@import './app/shared/modules/widgets/widgets.component.scss-theme';
@import './app/decisions/decision-list/decisions-list.component.scss-theme';
@import './app/decisions/report-item/report-item.component.scss-theme';
@import './app/shared/modules/signing-pad/signing-pad.component.scss-theme';
@import './app/shared/modules/decision-dialog/decision-dialog/decision-dialog.component.scss-theme';

@mixin list-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .column-nav {
    .bullet {
      &.active {
        &::before {
          box-shadow: inset 1px 1px 10px 0px rgba(white,0.4);
          border: 2px solid rgba(white, 0.4);
        }
      }
    }
  }
}

@mixin list-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .column-nav {
    .bullet {
      &.active {
        &::before {
          box-shadow: inset 1px 1px 10px 0px rgba(black, 0.4);
          border: 2px solid rgba(black, 0.4);
        }
      }
    }
  }
}


@mixin component-themes($theme) {
  // add general component mixins
  @include sidenav-theme($theme);
  @include material-button-theme($theme);
  @include material-progress-bar-theme($theme);
  /* ... */
}

@mixin light-component-themes($theme) {
  // add light component mixins
  @include navbar-theme($theme);
  @include list-component-theme($theme);
  // @include manage-card-detail-component-theme($theme);
  // @include manage-card-detail-text-edit-component-theme($theme);
  // @include fact-box-component-theme($theme);
  @include auth-component-theme($theme);
  @include widgets-theme($theme);
  @include manage-meeting-manager-component-theme($theme);
  @include meeting-detail-component-theme($theme);
  // @include manage-meeting-list-component-theme($theme);
  @include decider-view-component-theme($theme);
  @include meeting-presentation-view-component-theme($theme);
  @include search-component-theme($theme);
  @include meeting-minutes-theme($theme);
  @include group-filter-component-theme($theme);
  @include algolia-decision-list-item-component-theme($theme);
  @include algolia-circular-resolution-list-item-component-theme($theme);
  @include circular-resolution-component-theme($theme);
  @include decision-list-component-theme($theme);
  @include meeting-decision-report-item-theme($theme);
  @include signing-pad-component-theme($theme);
  @include decision-dialog-component-theme($theme);
  /* ... */
}

@mixin dark-component-themes($theme) {
  // add dark component mixins
  @include navbar-theme-dark($theme);
  @include list-component-theme-dark($theme);
  // @include manage-card-detail-component-theme-dark($theme);
  // @include manage-card-detail-text-edit-component-theme-dark($theme);
  // @include fact-box-component-theme-dark($theme);
  @include auth-component-theme-dark($theme);
  @include widgets-theme-dark($theme);
  @include manage-meeting-manager-component-theme-dark($theme);
  @include meeting-detail-component-theme-dark($theme);
  @include manage-meeting-list-component-theme-dark($theme);
  @include decider-view-component-theme-dark($theme);
  @include meeting-presentation-view-component-theme-dark($theme);
  @include search-component-theme-dark($theme);
  @include meeting-minutes-theme-dark($theme);
  @include group-filter-component-theme-dark($theme);
  @include algolia-decision-list-item-component-theme-dark($theme);
  @include algolia-circular-resolution-list-item-component-theme-dark($theme);
  @include circular-resolution-component-theme-dark($theme);
  @include decision-list-component-theme-dark($theme);
  @include meeting-decision-report-item-theme-dark($theme);
  @include signing-pad-component-theme-dark($theme);
  @include decision-dialog-component-theme-dark($theme);
  /* ... */
}

.wedecide-light-theme {
  @include mat.all-component-themes($wedecide-light-theme);
  @include component-themes($wedecide-light-theme);
  @include light-component-themes($wedecide-dark-theme);
}
.wedecide-dark-theme {
  @include mat.all-component-themes($wedecide-dark-theme);
  @include component-themes($wedecide-dark-theme);
  @include dark-component-themes($wedecide-dark-theme);
}


.bg-gray {
  background-color: $wd-gray !important;
}
