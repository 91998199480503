@import '../../../themes/base_colors';
@import '../../../themes/color_palette';

@mixin meeting-decision-report-item-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .report {
    .report-header {
      background: rgba(black, 0.025);
    }

    &.readonly {
      .report-header {
        background: transparent;
      }
      
      background: rgba(black, 0.025);
    }
  }
}

@mixin meeting-decision-report-item-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .report {
    .report-header {
      background: rgba(white, 0.025);
    }

    &.readonly {
      .report-header {
        background: transparent;
      }

      background: rgba(white, 0.025);
    }
  }
}
