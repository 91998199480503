mention-list {

  background-color: #eeeeee;
  position: "absolute";
  border-radius: 5px;
  border-width: 3px;
  z-index: 1000;

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .dropdown-menu {
    position: absolute;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .dropdown-item {
    display: block;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    //background-color: transparent;
    border: 0;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: steelblue;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color:steelblue;
  }

  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
  }

  // is overwritten by the component itself
  li.active {
      background-color: steelblue;
  }
}
