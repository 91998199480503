@import '../base_colors';

/**
 * Custom progress bar colors
 * Colors: yellow, gray
 **/

@mixin material-progress-bar-theme($theme) {
  .mat-progress-bar{
    &.mat-gray {
      .mat-progress-bar-fill::after {
        background-color: $wd-gray !important;
      }
    }
    &.mat-yellow {
      .mat-progress-bar-fill::after {
        background-color: $wd-yellow !important;
      }
    }
  }
}
