@import '../../../themes/base_colors';
@import '../../../themes/color_palette';

@mixin manage-meeting-manager-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .breadcrumb-bar {
    background: $wd-light-background;
  }

  .meeting-header {
    background: rgba($wd-light-background, 0.95);
  }

  .date-separator {
    background: rgba(black, 0.02);
    border-top: 1px solid rgba(black, 0.08);
    border-bottom: 1px solid rgba(black, 0.08);
  }
}

@mixin manage-meeting-manager-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .breadcrumb-bar {
    background: $wd-dark-background;
  }

  .meeting-header {
    background: rgba($wd-dark-background, 0.95);
  }

  .date-separator {
    background: rgba(white, 0.02);
    border-top: 1px solid rgba(white, 0.08);
    border-bottom: 1px solid rgba(white, 0.08);
  }
}
