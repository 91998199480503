@use '@angular/material' as mat;
@import './base_colors';

$primary: mat.define-palette($mat-primary, main, lighter, darker);
$accent: mat.define-palette($mat-accent, main, lighter, darker);
$warn: mat.define-palette($mat-warn, main, lighter, darker);
// $warn: mat-palette($wdred);
$background-color: $wd-white;

$wedecide-light-theme: mat.define-light-theme($primary, $accent, $warn);

// Insert custom background color
$background: map-get($wedecide-light-theme, background);
$background: map_merge(
  $background,
  (
    background: $background-color,
  )
);
$foreground: $wd-text;
$wedecide-light-theme: map_merge(
  $wedecide-light-theme,
  (
    background: $background,
  )
);

.wedecide-light-theme {
  color: $foreground;

  .wd-default {
    color: $wd-text !important;
  }

  a {
    color: mat.get-color-from-palette($primary);
  }

  .display-chip {
    background-color: #e0e0e0;
    color: $foreground;
  }

  // .chip {
  //   background-color: rgba($wd-dark-background, 20%);
  //   color: $wd-dark-background;
  // }

  .svg-icon svg path {
    fill: $wd-text;
    stroke: $wd-text;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none !important;
    -webkit-text-fill-color: $foreground !important;
    -webkit-box-shadow: none;
    background-image: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  .nda-check {
    color: lighten($foreground, 35%);
  }

  .mat-drawer-container,
  .mat-toolbar,
  .mat-dialog-container,
  .mat-flat-button:not([color]),
  .mat-raised-button:not([color]),
  .mat-fab:not([color]),
  .mat-mini-fab:not([color]) {
    color: $foreground !important;
  }

  .warning {
    color: mat.get-color-from-palette($warn);
  }

  .light-text {
    color: lighten($foreground, 30%);

    .mat-icon svg path {
      fill: lighten($foreground, 30%);
      stroke: lighten($foreground, 30%);
    }
  }

  [contenteditable='true']:empty:before {
    color: lighten($foreground, 30%);
  }

  .manage-container .column .disabled-column {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAO0lEQVQoU2NkIAIwElLz7tO7/3gVgRQI8QkxEjQJZBNWRTATYE7BUISuAKdJ6J6Bm4TNBBTr8CkAKQQAgGUX1zzIFYwAAAAASUVORK5CYII=)
      repeat;
  }

  .mat-tooltip.navbar-tooltip {
    background: mat.get-color-from-palette($primary);
    color: white;
  }

  // add light theme styles
  .manage-container .column {
    &.odd {
      background-color: rgba($wd-light-background, 0.4);
    }
    &.even {
      background-color: rgba($wd-light-background, 0.2);
    }
  }

  mat-card {
    color: $foreground !important;

    &:focus,
    &.focused {
      border: 1px solid mat.get-color-from-palette($primary);
      outline: none;
    }

    box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
      rgba(0, 0, 0, 0.05) 0px 2px 8px 0px !important;
    color: $foreground;

    &.item-card {
      border: 1px solid transparent;
    }

    &.no-hover {
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
      }
    }
  }

  .mat-chip.mat-standard-chip {
    color: $foreground;
  }

  .mat-progress-bar-buffer {
    background: #e4e8eb !important;
  }

  .side-dialog-header {
    background: $wd-light-background;
  }

  .side-dialog-footer {
    background: $wd-light-background;
  }

  // .savestate {
  //   background: #FCFCFC;
  // }

  .dialog-backdrop {
    background: rgba(0, 0, 0, 0.6);
  }

  .group-chip {
    background: rgba(black, 0.02);
    color: mat.get-color-from-palette($accent);
    border: 1px solid rgba(black, 0.08);
  }

  .role {
    background: rgba(black, 0.02);
    color: mat.get-color-from-palette($accent);
    border: 1px solid rgba(black, 0.08);
  }

  .chip {
    background-color: rgba($wd-accent, 20%);
    color: $wd-accent;
  }

  .mat-card:not([class*='mat-elevation-z']) {
    &.no-shadow {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .ais-Pagination-item--selected .ais-Pagination-link {
    background-color: mat.get-color-from-palette($accent);
    border-color: mat.get-color-from-palette($accent);
  }
}

.wd-gray {
  color: $wd-gray !important;
}
