@use '@angular/material' as mat;
@import '../../../../themes/base_colors';
@import '../../../../themes/color_palette';

@mixin group-filter-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-chip.group-filter-chip {
    background-color: darken($wd-light-background, 1%);
    border: 1px solid lighten(lightgray, 10%);
  
    &.selected {
      background-color: mat.get-color-from-palette($primary) !important;
      border: 1px solid mat.get-color-from-palette($primary) !important;
      color: white !important
    }
  }
}

@mixin group-filter-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-chip.group-filter-chip {
    background-color: rgba(black, 0.05);
    border: 1px solid darken(darkgray, 35%);
  
    &.mat-chip.selected {
      background-color: mat.get-color-from-palette($primary) !important;
      border: 1px solid mat.get-color-from-palette($primary) !important;
      color: white !important
    }
  }
}
