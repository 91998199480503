@use '@angular/material' as mat;
@import '../../../../themes/base_colors';
@import '../../../../themes/color_palette';

@mixin algolia-circular-resolution-list-item-component-theme($theme) {
  .icon {
    mat-icon {
      color: $wd-gray;

			#Shape {
        fill: $wd-gray;
      }
    }
  }
}

@mixin algolia-circular-resolution-list-item-component-theme-dark($theme) {
  $foreground: map-get($theme, foreground);

  .icon {
    mat-icon {
      color: mat.get-color-from-palette($foreground);

      #Shape {
        fill: white;
      }
    }
  }
}
