@import '../../../../themes/base_colors';

@mixin widgets-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  mat-card.item-card {
    &.disabled {
      background: rgba(black ,0.08);
    }
  }

  .notification-item {
    .notification-card {
      background: darken($wd-light-background, 2%);
    }

    &.read {
      .notification-card {
        background: rgba(darken($wd-light-background, 5%), 90%);
      }
    }
  }
}

@mixin widgets-theme-dark($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  mat-card.item-card {
    background: lighten(#424242, 5%);
    &.disabled {
      background: rgba(white, 0.08);
    }
  }

  .notification-item {
    .notification-card {
      background: rgba(lighten($wd-dark-background, 8%), 90%);
    }
    &.read {
      .notification-card {
        background: rgba($wd-dark-background, 70%);
      }
    }
  }
}
