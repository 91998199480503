@import '~@recogito/recogito-js/dist/recogito.min.css';

// hide tag input
.r6o-widget.r6o-tag {
  display: none;
}

// hide reply option (by hiding all comments but the first)
.r6o-widget.comment ~ .comment {
  display: none;
}

.r6o-annotation {
  &.focused {
    background-color: rgba(65, 163, 228, 0.2);
    border-bottom: 2px solid #35b9c6;
    padding: 5px 10px;
  }

  $colors: (
    '1': #8aa399,
    '2': purple,
    '3': #dbf4a7,
    '4': darkkhaki,
    '5': #8fa6cb,
    '6': #7d84b2,
    '7': olive,
    '8': pink,
    '9': teal,
    '10': tan,
  );
  @each $i, $c in $colors {
    &.style-#{$i} {
      background-color: rgba($c, 0.3);
      border-color: $c;
    }
  }
}

.r6o-editor {
  .r6o-editor-inner {
    &:hover {
      border: 1px solid #35b9c6;
    }
    .comment {
      background-color: transparent;
    }
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 5px;
  }

  .r6o-btn {
    background-color: #35b9c6;
    border: 0 solid white;
    border-radius: 4px;
    color: #fff;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    padding: 0 16px;

    &.outline {
      background: transparent;
      border: 0 solid white;
      color: rgb(61, 71, 77);

      &:hover {
        background-color: #eee;
      }
    }
  }
}
