@use '@angular/material' as mat;

@mixin sidenav-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);


  .mat-list-base .mat-list-item {
    &.active {
      background-color: mat.get-color-from-palette($primary);
      color: white;
    }
  }

}

