@import '../../themes/base_colors';
@import '../../themes/color_palette';

@mixin search-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .search-selector {
    .mat-chip {
      background-color: lighten(lightgray, 15%) !important;
      border: 1px solid lighten(lightgray, 10%) !important;
    }
  }

  // TODO: replace with global mat-chip styling
  mat-chip.mat-chip.mat-standard-chip.light {
    background-color: #F9F9F9;
    border: 1px solid #ededed !important;
  }
}

@mixin search-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .ais-SearchBox {
    .ais-SearchBox-input {
      background: rgba(white, 0.12);
      border: 1px solid rgba(white, 0.15);
    }
    .ais-SearchBox-resetIcon path, .ais-SearchBox-submitIcon path {
      fill: white;
    }
  }

  .search-selector {
    .mat-chip {
      margin-left: 15px !important;
      cursor: pointer;
      background-color: lighten(rgb(131, 128, 128), 15%) !important;
      border: 1px solid lighten(#333, 10%) !important;
    }
  }

  // TODO: replace with global mat-chip styling
  mat-chip.mat-chip.mat-standard-chip.light {
    background-color: lighten(#333, 15%) !important;
    border: 1px solid lighten(#333, 10%) !important;
  }
}
