@import '../../../themes/base_colors';
@import '../../../themes/color_palette';

@mixin manage-meeting-list-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .meeting-header {
    background: rgba($wd-light-background, 0.95);
  }
}

@mixin manage-meeting-list-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .meeting-header {
    background: rgba($wd-dark-background, 0.95);
  }
}
