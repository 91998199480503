@import '../base_colors';

/**
 * Custom material button colors
 * Colors: yellow, gray
 **/

@mixin material-button-theme($theme) {
  .mat-button,
  .mat-stroked-button {
    &.mat-yellow {
      color: $wd-yellow;
    }
    &.mat-gray {
      color: $wd-gray;
    }
    &.mat-purple-light {
      color: $wd-purple-light;
    }
    &.mat-purple {
      color: $wd-purple;
    }
    &.mat-purple-light {
      color: $wd-purple-light;
    }
    &.mat-blue-light {
      color: $wd-blue-light;
    }
  }

  .mat-raised-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    &.mat-green {
      color: white;
      background-color: $wd-green;
    }
    &.mat-red {
      color: white;
      background-color: $wd-red;
    }

    &.mat-yellow {
      color: white;
      background-color: $wd-yellow;
    }
    &.mat-gray {
      color: white;
      background-color: $wd-gray;
    }
    &.mat-purple-light {
      color: white;
      background-color: $wd-purple-light;
    }
    &.mat-purple {
      color: white;
      background-color: $wd-purple;
    }
    &.mat-purple-light {
      color: white;
      background-color: $wd-purple-light;
    }
    &.mat-blue-light {
      color: white;
      background-color: $wd-blue-light;
    }
  }

  .mat-icon-button {
    &.mat-yellow {
      color: $wd-yellow;
    }
    &.mat-gray {
      color: $wd-gray;
    }
    &.mat-purple-light {
      color: $wd-purple-light;
    }
    &.mat-purple {
      color: $wd-purple;
    }
    &.mat-purple-light {
      color: $wd-purple-light;
    }
    &.mat-blue-light {
      color: $wd-blue-light;
    }
  }
}

.mat-flat-button,
.mat-raised-button {
  &.large {
    padding: 8px 16px;
    font-size: 15px;
  }

  &.rounded {
    border-radius: 40px !important;
  }

  &.fab {
    z-index: 1000;
  }
}
