@import '../../../../themes/base_colors';

@mixin navbar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, #3d474d);
  $background: map-get($theme, background);

  wd-navbar {
    .navbar {
      background-color: white;
      &.show-impersonation {
        background: linear-gradient(180deg, $wd-orange 0%, $wd-orange 5%, rgba(255,255,255,1) 6%, rgba(255,255,255,1) 100%);
      }
      color: $foreground;

      .title {
        border-left: 1px solid rgba(lightgray, 50%);
      }

      .nav-item {
        color: $wd-text !important;

        &:hover {
          color: darken($wd-text, 15%) !important;
        }

        &:after {
          border-bottom: solid 2px rgba($wd-primary, 25%);
        }

        &.active {
          color: $wd-primary !important;
          &:after {
            border-color: rgba($wd-primary, 70%);
          }
        }
      }
    }

    .mat-icon-button.active-btn {
      background: rgba(black, 10%);
      color: $wd-primary;
    }
  }

  mat-card.navbar-popover {
    background: darken(white, 2%);
  }

}

@mixin navbar-theme-dark($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  wd-navbar {
    .navbar {
      background-color: #222;
      color: white;
      &.show-impersonation {
        background: linear-gradient(180deg, $wd-orange 0%, $wd-orange 5%, rgba(34, 34, 34,1) 6%, rgba(34, 34, 34,1) 100%);
      }
    }

    .nav-item {
      color: white !important;

      &:after {
        border-bottom: solid 2px rgba($wd-primary, 40%);
      }

      &:hover {
        color: darken(white, 15%) !important;
      }

      &.active {
        color: $wd-primary !important;
        &:after {
          border-color: rgba($wd-primary, 70%);
        }
      }
    }

    .mat-icon-button.active-btn {
      background: rgba(white, 10%);
      color: $wd-primary;
    }
  }

  mat-card.navbar-popover {
    background: lighten(#222, 2%);
  }

}
