@use '@angular/material' as mat;
@import '../../../../themes/base_colors';
@import '../../../../themes/color_palette';

@mixin algolia-decision-list-item-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .icon {
    background: adjust-color(mat.get-color-from-palette($primary), $lightness: 40%);

    mat-icon {
      color: $wd-gray;
    }
  }
}

@mixin algolia-decision-list-item-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  .icon {
    background: adjust-color(mat.get-color-from-palette($primary), $lightness: -30%, $saturation: -20%);

    mat-icon {
      color: mat.get-color-from-palette($foreground);
    }
  }
}
