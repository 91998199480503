@import '../../../themes/base_colors';
@import '../../../themes/color_palette';

@mixin meeting-detail-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .meeting-subheader-container, .meeting-header-container {
    background: rgba($wd-light-background, 0.95);
  }

  .mat-card.decision-bar {
    border: 1px solid transparent !important;

    &:hover {
      background: #f5f5f5 !important;
      border-style: none !important;
      border: 1px solid transparent !important;
    }
  }
}

@mixin meeting-detail-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .meeting-subheader-container, .meeting-header-container {
    background: rgba($wd-dark-background, 0.95);
  }

  .mat-card.decision-bar {
    border: 1px solid transparent !important;

    &:hover {
      background: darken(#424242, 5%) !important;
      border-style: none !important;
      border: 1px solid transparent !important;
    }
  }
}
