@use '@angular/material' as mat;
@import './base_colors.scss';

$primary: mat.define-palette($mat-primary, main, lighter, darker);
$accent: mat.define-palette($mat-primary, main, lighter, darker);
$warn: mat.define-palette($mat-warn, main, lighter, darker);
$background: mat.define-palette($mat-background-dark, main, lighter, darker);
$wedecide-dark-theme: mat.define-dark-theme($primary, $accent, $warn);

.wedecide-dark-theme {
  background: $wd-dark-background;

  .wd-accent {
    color: mat.get-color-from-palette($accent) !important;
  }

  .bg-wd-accent {
    background-color: mat.get-color-from-palette($accent) !important;
  }

  .wd-default {
    color: $wd-white !important;
  }

  a {
    color: mat.get-color-from-palette($primary);
  }

  .display-chip {
    background-color: #616161;
    color: white;
  }

  .svg-icon svg path {
    fill: white;
    stroke: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none !important;
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  .nda-check {
    color: darken(white, 35%);
  }

  .warning {
    color: mat.get-color-from-palette($warn);
  }

  .light-text {
    color: lighten(#000, 75%);

    .mat-icon svg path {
      fill: lighten(#000, 50%);
      stroke: lighten(#000, 50%);
    }
  }

  [contenteditable='true']:empty:before {
    color: darken(#fff, 50%);
  }

  .manage-container .column .disabled-column {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAN0lEQVQoU2NkIAIwEqHmPyFF/xkYGBgJKQJbhEsR2ASYU7ApQlGAzyQU/yCbhGECunU4FYAUAgBp4wYJ31jKswAAAABJRU5ErkJggg==)
      repeat;
  }

  .mat-tooltip.navbar-tooltip {
    background: rgba(black, 90%) !important;
    color: white;
  }

  // add dark theme styles
  mat-card {
    &:focus,
    &.focused {
      border: 1px solid mat.get-color-from-palette($primary);
      outline: none;
    }

    box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
      rgba(0, 0, 0, 0.05) 0px 2px 8px 0px !important;

    &.item-card {
      border: 1px solid transparent;
    }

    &.no-hover {
      border: 1px solid rgba(255, 255, 255, 0.12) !important;
      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.12) !important;
      }
    }
  }

  .mat-progress-bar-buffer {
    background: darken(#424242, 5%) !important;
  }

  .side-dialog-header {
    background: $wd-dark-background;
  }

  .side-dialog-footer {
    background: $wd-dark-background;
  }

  .dialog-backdrop {
    background: rgba(0, 0, 0, 0.6);
  }

  .group-chip {
    background: lighten($wd-dark-background, 10%);
    color: mat.get-color-from-palette($primary);
    border: 1px solid lighten($wd-dark-background, 15%);
  }

  .role {
    background: lighten($wd-dark-background, 10%);
    color: mat.get-color-from-palette($primary);
    border: 1px solid lighten($wd-dark-background, 15%);
  }

  .chip {
    background-color: rgba($wd-primary, 20%);
    color: $wd-primary;
  }

  .mat-card:not([class*='mat-elevation-z']) {
    &.no-shadow {
      border: 1px solid rgba(white, 0.12);
    }
  }

  .ais-SearchBox-input {
    color: #fff;
  }

  // text color of labels
  .ngx-charts {
    text {
      fill: #fff;
    }
  }

  .r6o-editor {
    border: 1px solid #5f5f5f;
    border-radius: 5px;
    .r6o-arrow {
      color: #313131;
      background-color: transparent;

      &:after {
        background-color: #31393e;
        border: 1px solid #5f5f5f;
      }
    }

    .r6o-arrow-down {
      background-color: #313131;
    }
    .r6o-comment-dropdown-menu {
      border-radius: 5px;
      background-color: #313131;

      li {
        &:hover {
          background-color: #5f5f5f;
        }
      }
    }

    .r6o-lastmodified {
      border: 1px solid #5f5f5f;
    }

    .r6o-widget {
      border-color: #5f5f5f;
    }

    .r6o-editor-inner {
      background-color: #31393e;
      color: whitesmoke;
      border: none;

      &:hover {
        border: 1px solid #5f5f5f;
      }
      .comment {
        border: 1px solid #5f5f5f;
      }

      .r6o-editable-text {
        color: whitesmoke;
      }

      .r6o-btn {
        background-color: #35b9c6;
        color: whitesmoke;

        &.outline {
          background: transparent;
          border: 1 solid whitesmoke;
          color: whitesmoke;

          &:hover {
            background-color: #5f5f5f;
          }
        }
      }
    }
  }

  .bottom-bar-container {
    background: darken(#424242, 5%) !important;
  }

  .ais-Pagination-item--selected .ais-Pagination-link {
    background-color: mat.get-color-from-palette($primary);
    border-color: mat.get-color-from-palette($primary);
  }
}

.duedate-chip {
  &.gray {
    background-color: rgba(lighten($wd-gray, 10%), 20%); //rgba($wd-gray, 20%);
    color: lighten($wd-gray, 15%) !important;
  }
}

.wd-gray {
  color: lighten($wd-gray, 15%) !important;
}
