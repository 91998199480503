@use 'sass:map';
@import './themes/base_colors';

.pointer {
  cursor: pointer;
}

$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 75, 80, 90, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

$wdcolors: (
  'primary': $wd-primary,
  'accent': $wd-accent,
  'warn': $wd-warn,
  'primary-light': $wd-primary-light,
  'white': $wd-white,
  'black': $wd-black,
  'blue': $wd-blue,
  'green': $wd-green,
  'yellow': $wd-yellow,
  'red': $wd-red,
  'background': $wd-background,
  'blue-light': $wd-blue-light,
  'purple': $wd-purple,
  'purple-light': $wd-purple-light,
  'dark-gray': #555,
  'orange': $wd-orange,
);

$alphas: (
  '10': 0.1,
  '20': 0.2,
  '30': 0.3,
  '40': 0.4,
  '50': 0.5,
  '60': 0.6,
  '70': 0.7,
  '80': 0.8,
  '90': 0.9,
  '100': 1,
);

@each $n, $c in $wdcolors {
  .wd-#{$n} {
    color: #{$c} !important;
  }

  .bg-wd-#{$n} {
    background-color: #{$c} !important;
  }
}

@each $i, $t in $alphas {
  @each $n, $c in $wdcolors {
    .wd-#{$n}-#{$i} {
      color: rgba($c, $t) !important;
    }

    .bg-wd-#{$n}-#{$i} {
      background-color: rgba($c, $t) !important;
    }
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

#hubspot-body {
  &.hubspot-move-up {
    #hubspot-messages-iframe-container {
      z-index: 2;
      bottom: 50px !important;
    }
  }

  &.hubspot-hide {
    #hubspot-messages-iframe-container {
      visibility: hidden !important;
    }
  }

  &.hubspot-move-in-400 {
    #hubspot-messages-iframe-container {
      animation: move-in-400 0.5s forwards;
    }
  }

  &.hubspot-move-out-400 {
    #hubspot-messages-iframe-container {
      animation: move-out-400 0.5s forwards;
    }
  }

  &.hubspot-move-in-600 {
    #hubspot-messages-iframe-container {
      animation: move-in-600 0.5s forwards;
    }
  }

  &.hubspot-move-out-600 {
    #hubspot-messages-iframe-container {
      animation: move-out-600 0.5s forwards;
    }
  }

  @keyframes move-in-400 {
    100% {
      transform: translateX(-400px);
    }
  }

  @keyframes move-out-400 {
    0% {
      transform: translateX(-400px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes move-in-600 {
    100% {
      transform: translateX(-600px);
    }
  }

  @keyframes move-out-600 {
    0% {
      transform: translateX(-600px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

mat-icon.md {
  width: 18px;
  height: 18px;
  font-size: 18px;
  &.centered {
    height: inherit;
  }
}

mat-icon.sm {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

mat-icon.xs {
  width: 14px;
  height: 14px;
  font-size: 14px;
}

.decision-detail-dialog .mat-dialog-container {
  padding: 0 !important;
}

.pre-line {
  white-space: pre-line;
}

.inline-block {
  display: inline-block;
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  font-style: italic;
}

[contenteditable='true']:focus {
  outline: none;
}

.ready-to-decide-button {
  background-color: $wd-yellow !important;
  color: white !important;
}

.mat-raised-button.mat-prepare:not([disabled]),
.mat-flat-button.mat-prepare:not([disabled]),
.mat-fab.mat-prepare:not([disabled]),
.mat-mini-fab.prepare:not([disabled]) {
  color: #fff !important;
  background-color: $wd-red !important;
}

.mat-raised-button.mat-ready-to-decide:not([disabled]),
.mat-flat-button.mat-ready-to-decide:not([disabled]),
.mat-fab.mat-ready-to-decide:not([disabled]),
.mat-mini-fab.ready-to-decide:not([disabled]) {
  color: #fff !important;
  background-color: $wd-yellow !important;
}

.mat-raised-button.mat-decice:not([disabled]),
.mat-flat-button.mat-decide:not([disabled]),
.mat-fab.mat-decide:not([disabled]),
.mat-mini-fab.decide:not([disabled]) {
  color: #fff !important;
  background-color: $wd-primary !important;
}

.mat-raised-button.mat-evaluate:not([disabled]),
.mat-flat-button.mat-evaluate:not([disabled]),
.mat-fab.mat-evaluate:not([disabled]),
.mat-mini-fab.evaluate:not([disabled]) {
  color: #fff !important;
  background-color: $wd-primary !important;
}

::ng-deep .mat-standard-chip:focus::after {
  opacity: 0 !important;
}

::ng-deep .mat-standard-chip:hover::after {
  opacity: 0 !important;
}

.mat-standard-chip::after {
  display: none;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.mat-tooltip.navbar-tooltip {
  font-size: 15px;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-top: 3px;
}

.display-chip {
  font-size: 13px;
  font-weight: 300;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 6px 10px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 13px;
  height: 1px;
  margin-bottom: 3px;
}

.manage-container {
  .column-content {
    .card-wrapper:last-child {
      wd-card {
        mat-card.item-card {
          margin-bottom: 80px;
        }
      }
    }
  }
}

.no-focus {
  &:focus {
    outline: none !important;
  }
}

.mat-progress-bar {
  border-radius: 2px;
}

.mat-card,
.mat-expansion-panel-header {
  font-family: 'Lato', 'Roboto', 'Helvetica Neue', sans-serif;
}

mat-card.item-card {
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;

  .mat-card-header {
    ::ng-deep .mat-card-header-text {
      margin: 0;
    }

    .mat-card-title {
      font-weight: 400;
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  mat-card-actions {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .mat-icon {
      transform: scale(0.85);
    }
  }
}

.no-padding-dialog {
  mat-dialog-container.mat-dialog-container {
    padding: 0;
    border-radius: 0px;
  }
}

.icon-white svg path {
  fill: white;
  stroke: white;
}

.icon-blue svg path {
  fill: $wd-primary;
  stroke: $wd-primary;
}

.icon-gray svg path {
  fill: $wd-gray;
  stroke: $wd-gray;
}

.icon-white svg path {
  fill: white;
  stroke: white;
}

.icon-purple-light svg path {
  fill: $wd-purple-light;
  stroke: $wd-purple-light;
}

.add-button-container {
  position: fixed;
  right: 40px;
  bottom: 40px;
}

.side-dialog-content {
  padding: 48px 0 74px 0;
}

.side-dialog-header {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.side-dialog-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  height: 64px;
  margin-bottom: 0px;
  padding: 0 10px;
}

mat-checkbox.mat-checkbox-disabled {
  label {
    span.mat-checkbox-inner-container {
      span.mat-checkbox-frame {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.bottom-info-banner {
  position: fixed;
  z-index: 99;
  background: darken($wd_gray, 10%);
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 20px;
  padding: 5px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .bottom-info-banner {
    padding: 5px 80px 5px 10px;
    text-align: left;
  }
}

.number-of {
  margin-left: 4px;
  margin-right: 4px;
}

@media screen and (max-width: 960px) {
  .r6o-editor {
    max-width: 350px;

    .r6o-widget {
      .r6o-icon {
        min-width: 32px;
        min-height: 32px;
        border-radius: 5px;

        svg {
          padding-top: 7px;
          padding-bottom: 7px;
          min-width: 18px;
          min-height: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .r6o-editor {
    max-width: 300px;

    .r6o-widget {
      .r6o-icon {
        min-width: 32px;
        min-height: 32px;
        border-radius: 5px;

        svg {
          padding-top: 7px;
          padding-bottom: 7px;
          min-width: 18px;
          min-height: 18px;
        }
      }
    }
  }
}

input[type='time']::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
  background: none;
}
