/* change colors also in pspdf.css */
$wd-primary: #35b9c6;
$wd-accent: #2e7d9e;
$wd-warn: #f2506b;
$wd-primary-light: lighten($wd-primary, 5%);
$wd-blue: #41a3e4;
$wd-blue-light: #3ec5ed;
$wd-purple: #9c72c6;
$wd-purple-light: #cc7fd6;
$wd-green: #35c69b;
$wd-yellow: #ffa000;
$wd-red: $wd-warn;
$wd-orange: #e67e22;
$wd-background: #c6d5c8;
$wd-gray: #62747f;
$wd-gray-background: #f0f0f0;
$wd-text: #3d474d;
$wd-white: #ffffff;
$wd-black: #000000;
$wd-light-background: $wd-white;
$wd-dark-background: #303030;
$wd-dark-primary-text: $wd-white;
